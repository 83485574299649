<template>
    <div>
        <pretty-select
            label="countryName"
            v-model="localValue"
            :options="countries"
            placeholder="Country"
            :customPlaceholder="customPlaceholder"
            :required="required"
            :reduce="reduce"
            :shrunk-label="shrunkLabel"
            :multiple="multiple"
            :select-on-tab="true"
            @keypress.enter.prevent="() => {}"
            class="country-selector"
            :class="{'country-selector--is': !!localValue}"
        />
        <span class="pretty-select-error" v-if="$v && $v.$error">
            Country is required
        </span>
    </div>
</template>

<script>
import countries from 'country-region-data'
import PrettySelect from '@/components/utils/PrettySelect'
export default {
    name: 'CountrySelector',
    components: { PrettySelect },
    props: {
        value: {
            default: null,
        },
        $v: {
            type: Object,
        },
        reducer: {
            default: 'countryShortCode',
        },
        shrunkLabel: {
            default: false,
        },
        customPlaceholder: {
            default: null,
        },
        isLowerCase: {
            default: false,
        },
        required: {
            default: false,
        },
        multiple: {
            default: false,
        },
    },
    data () {
        return {
            countries,
        }
    },
    computed: {
        localValue: {
            get () {
                if ((!this.value || this.value === '') && this.multiple) {
                    return []
                }
                return this.value
            },
            set (value) {
                if (this.isLowerCase) {
                    this.$emit('input', value.toLowerCase())
                    return
                }
                this.$emit('input', value)
            },
        },
        reduce () {
            return country => country[this.reducer]
        },
    },
}
</script>
